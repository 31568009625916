import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
/**
 * Internal components
 */

import MenuLink from '../atoms/MenuLink'

const Menu = ( { menu, current, className } ) => {

	const links = menu.items

	console.log( 'menu', menu )

	return (
		<nav className={`nav ${className}`}>
			<ul className="menu">
				{
					links.map( link => (
						<li className={`menu-item menu-item-${link.wordpress_id}`} key={link.wordpress_id}>
							<MenuLink {...link} />
						</li>
					) )
				}
			</ul>
		</nav>
	)
}

Menu.propTypes = {
	menu: PropTypes.object.isRequired,
	current: PropTypes.string,
}

Menu.defaultProps = {
	menu: {},
}

export default styled( Menu )`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 90vw;
    max-height: 100vh;
    width: 100%;
    opacity: 0;
    transition: opacity .3s ease;
    overflow-y: auto;
    flex: none;
    .menu-open & {
       opacity: 1;
       transition: opacity .4s ease .4s;      
    }
    
    .menu {
        display: block;
    	@media screen and (min-width: 769px) {
			display: flex;
			justify-content: center;
            align-items: center;
		}
     .menu-open & {
        
        max-height: 100vh;
        width: 100%;     
     }
    }
	ul {
		list-style: none;
	
	}
	
    ul,
    li {
        margin: 0;
        padding: 0;
    }
   
    li {
       padding: 0 1rem 1.25rem;
       @media screen and (min-width: 769px) {
             padding: 0 1rem;
        }
    }
    
    a {
        display: inline-block;
       
        font-family: var(--headings-font-family);
	    font-weight: var(--headings-font-weight);
	    font-style: var(--headings-font-style);
        font-size: 1.5rem;
        transition: all .3s ease-in-out;
   
        &:after {
            content: '';
            display: block;
		    width: 0;
		    height: .125rem;
            background: var(--white);
            transition: width .3s ease-in-out;
        }
        @media screen and (min-width: 1023px) {
            font-size: 1.75rem;
        }
        &:hover,
        &.is-active {
			
			&:after {
				width: 100%;
			}
		}
    }	
	
`
