import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
/**
 * Internal components
 */

const Section = ( { children, className } ) => (
	<section className={`section ${className}`}>
		<div className="section__inner">
			{children}
		</div>
	</section>
)

Section.propTypes = {
	id: PropTypes.string,
	children: PropTypes.node.isRequired
}

export default styled(Section)``