import PropTypes from 'prop-types'
import React from 'react'

/**
 * Internal components
 */


import useRelative from '../particles/hooks/useRelative'


const PageLink = ( { title, url, target = '_self', className } ) => <a href={useRelative(url)} target={target} className={`link ${className}`}>{title}</a>

PageLink.propTypes = {
	title: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
	target: PropTypes.string,
}

export default PageLink
