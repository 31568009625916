import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

/**
 * Internal components
 */
import Section from './../organisms/Section'
import ParseHTML from '../particles/ParseHTML'
import Container from '../organisms/Container'

const PageHead = ( { content, className } ) => {

	const header = () => {
		if ( content.title ){
			return  (
				<header className="page-head__header"  data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease" data-sal-duration="750">
					<h1 className="title h1">{content.title}</h1>
				</header>
			)
		}
	}


	return (
		<Section className={`page-head ${className} section-space-${content.sectionSpacing}`} id={content.anchorId}>
			<Container className={`max-width-${content.maxWidth}`}>
				{header()}
				{content.content && <div className="the-content max-width-m"  data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease" data-sal-duration="750">{ParseHTML(content.content)}</div>}
			</Container>
		</Section>
	)
}

PageHead.propTypes = {
	content: PropTypes.object,
}

export default styled(PageHead)`

	.page-head__header {
		margin-bottom: 1rem; 
		@media screen and (min-width: 640px) {
			margin-bottom: 1.25rem; 
		}
	}
	
	.title {
		font-size: 2rem; 
		@media screen and (min-width: 640px) {
			font-size: 3rem; 
		}
	}
	
`