import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
/**
 * Internal components
 */
import useRelativeMenu from '../particles/hooks/useRelativeMenu'

const MenuLink = ( { url, title } ) => (
	<Link to={useRelativeMenu( url )} activeClassName={'is-active'}>{title}</Link>
)

MenuLink.propTypes = {
	url: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired
}

export default styled(MenuLink)`

`