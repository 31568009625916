import React from 'react'
import Parser from 'html-react-parser'
import { Link } from 'gatsby'
/**
 * Internal components
 */
import { isInternal } from '../helpers'
import useRelative from './hooks/useRelative'

const config = {
	replace: data => {
		const { attribs, parent } = data
		if ( attribs && attribs.href ) {
			const { children, href } = attribs

			if ( href && children )
				return <ReactAnchor attribs={attribs} children={children}/>
		}
	},
}

const ReactAnchor = ( { attribs, children } ) => {
	let { href } = attribs
	href = useRelative( href )
	if ( isInternal( href ) )
		return (
			<Link to={`${href}`}>
				{children && children.length > 0 && children[ 0 ].data}
			</Link>
		)
	if ( !isInternal( href ) )
		return (
			<a href={`${href}`}>
				{children && children.length > 0 && children[ 0 ].data}
			</a>
		)
}

const ParseHTML = html => {
	return Parser( html, config )
}

export { ParseHTML }
export default ParseHTML