import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const FooterBottom = ( { siteName, className } ) => {

	const currentYear = new Date().getFullYear()


	return (
		<div className={`footer-bottom ${className}`}>
			<p className="p copyright">&copy; {currentYear} {siteName}.</p>
		</div>
	)
}

FooterBottom.propTypes = {
	siteName: PropTypes.string
}

export default styled(FooterBottom)`
	margin-top: 2rem;
	padding-top: 1rem;
	padding-bottom: 1.5rem;
	border-top: 0.125rem dashed var(--primary-color-light);
	@media screen and (min-width: 641px) {
		margin-top: 3rem;
		padding-top: 1.5rem;
	}
	.p {
		margin: 0;
	}
`
