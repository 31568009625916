export const useRelativeMenu = url => {
	if (!url) return undefined
	const WPURL = process.env.WORDPRESS_BASE_URL

	console.log('WPURL', WPURL )
	let string = url
	if (url.startsWith(`/`)) return url
	string = string.replace(WPURL, "")
	console.log('String', string )
	return string
}

export default useRelativeMenu