import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from 'styled-components'


/**
 * Internal components
 */
import SiteLogo from '../atoms/SiteLogo'
import Container from './Container'
import Hamburger from './../atoms/Hamburger'
import Menu from './Menu'
import { useSiteMenuData } from '../particles/hooks/useSiteMenuData'
import PageLink from '../atoms/PageLink'
import { useSiteOptionsData } from '../particles/hooks/useSiteOptionsData'

const Header = ( { currentPage, className } ) => {

	const [menuOpen, toggleMenu] = useState( false )

	const menuData = useSiteMenuData()
	const optionsData = useSiteOptionsData()
	const headerMenu = menuData.find( menu => menu.node.name === 'main-menu' )
	const enquire = optionsData[ 0 ][ 'options' ][ 'contact_page' ]

	return (
		<header className={`site-header ${className}`}>
			<Container className="max-width-xxl">
				<Hamburger onClick={() => toggleMenu( !menuOpen )} open={menuOpen}/>
				<SiteLogo/>
				<div className="primary-navigation">
					<div className="primary-navigation-canvas">
						<Menu menu={headerMenu.node} current={currentPage}/>
					</div>
				</div>
				{enquire && <PageLink url={enquire} title="Enquire now" className="enquire-now"/>}

			</Container>
		</header>
	)
}

Header.propTypes = {
	currentPage: PropTypes.string
}

export default styled( Header )`
	padding: 1rem 0;
	@media screen and (min-width: 641px) {
		padding: 1.5rem 0;
	}
	
	.container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		
	}
	
	.site-logo {
		@media screen and (max-width: 640px) {
			order: 1;
		}
	}	
	.hamburger {
		@media screen and (max-width: 640px) {
			order: 2;
		}
	}
	.primary-navigation {
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        pointer-events: none;
        visibility: hidden;
        overflow: hidden;
        backface-visibility: hidden;
        outline: 1px solid transparent;
        .menu-open & {
            z-index: var(--z-index-medium);
            pointer-events: auto;
            visibility: visible;        
        }	
	}
	.primary-navigation-canvas {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 300vw;
        height: 300vw;
        border-radius: 50%;
        color: var(--white);
        transition: all .3s ease;
        flex: none;
        transform: scale(0);
        backface-visibility: hidden;
        overflow: hidden;
        .menu-open & {
            background: var(--primary-color);
            background-size: cover;
            transform: scale(1);
            transition-duration: .75s;        
        }
	}
	
	.enquire-now {
		position: relative;
	    color: var(--primary-color);
	    font-family: var(--headings-font-family);
	    font-weight: var(--headings-font-weight);
	    font-style: var(--headings-font-style);
	    text-transform: uppercase;
	    letter-spacing: 0.1em;
	    width: 8.4rem;
	    transition: all .3s ease-in-out;
		@media screen and (max-width: 640px) {
			display: none;
		}
		.menu-open & {
			color: var(--white);
			z-index: var(--z-index-high);
		}
		&:hover{	
			&:after {
				width: 100%;
			}
		}
		&:after {
            content: '';
            display: block;
		    width: 0;
		    height: .125rem;
            background: var(--primary-color);
            transition: width .3s ease-in-out;
        }
	}
`
