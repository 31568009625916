import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
/**
 * Internal components
 */
import Container from './Container'
import FooterBottom from './../molecules/FooterBottom'
import FooterTop from './../molecules/FooterTop'

const Footer = ( { siteName, currentPage, className} ) => {

	return (
		<footer className={`siteFooter ${className}`}>
			<Container className="max-width-xxl">
				<FooterTop current={currentPage} />
				<FooterBottom siteName={siteName} />
			</Container>
		</footer>
	)
}

Footer.propTypes = {
	siteName: PropTypes.string
}

export default styled(Footer)`
	margin-top: 3rem;
	@media screen and (min-width: 640px) {
		margin-top: 5rem;
	}	
	
	@media screen and (max-width: 639px) {
		.p,
		li {
			font-size: 0.875rem;
		}
	}
`
