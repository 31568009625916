/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
/**
 * Internal components
 */
import Header from '../organisms/Header'
import Footer from '../organisms/Footer'
import SEO from '../particles/SEO'

import './../../scss/common.scss'

const Layout = ( { children, context } ) => {

	const { wordpress } = useStaticQuery(
		graphql`
            query GeneralSettings {
                wordpress {
                    generalSettings {
                        title
                    }
                }
            }
		`
	)
	console.log('context', context)
	const siteName = wordpress.generalSettings.title

	let url
	if ( context !== undefined ) {
		url = context.uri
	} else {
		url = '/404'
	}

	return (
		<>
			<SEO data={context}/>
			<Header currentPage={url}/>
			<main id="main" className="main">{children}</main>
			<Footer siteName={siteName} currentPage={url}/>
		</>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout
