import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import MenuLink from '../atoms/MenuLink'
/**
 * Internal components
 */


const FooterMenu = ( { menu, current, className } ) => {

  const items = menu.items

	console.log('links', menu )

	const isCurrent = ( item ) => {
		return item.slug === current.replace( /\//g, '' ) ? ' is-current': ''
	}


	return (
		<nav className={`nav ${className}`}>
			<ul className="menu">
				{
					items.map( item  => (
						<li className={`menu-item menu-item-${item.wordpress_id}${isCurrent(item)}`} key={item.wordpress_id}>
							<MenuLink {...item} />
						</li>
					))
				}
			</ul>
		</nav>
	)
}

FooterMenu.propTypes = {
	menu: PropTypes.object.isRequired,
	current: PropTypes.string,
}

FooterMenu.defaultProps = {
	menu: {},
}

export default styled(FooterMenu) `
	ul {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: auto;
		column-gap: 1rem;
		row-gap: 0.875rem;
		padding: 0;
		list-style: none;
		@media screen and (min-width: 767px) and (max-width: 1279px) {
			grid-template-columns: 1fr 1fr 1fr 1fr;
			row-gap: 0;
		}	
		@media screen and (max-width: 321px) {
			grid-template-columns: 1fr;
		}
	}
	
	a {
		color: var(--primary-color);
		font-weight: var(--font-weight-bold);
	}

`
