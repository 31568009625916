import React from 'react'
import styled from 'styled-components'

/**
 * Internal components
 */
import { useSiteOptionsData } from '../particles/hooks/useSiteOptionsData'
import { useSiteMenuData } from '../particles/hooks/useSiteMenuData'
import { useSiteSocialData } from '../particles/hooks/useSiteSocialData'
import FooterWidget from './FooterWidget'
import FooterMenu from './FooterMenu'
import SocialWidget from './SocialWidget'

const FooterTop = ( { className, current } ) => {

	const optionsData = useSiteOptionsData()
	const menuData = useSiteMenuData()
	const socialMedia = useSiteSocialData()

	const footerMenu = menuData.find( menu => menu.node.name === 'footer-menu' )
	const email = optionsData[ 0 ][ 'options' ][ 'email' ]
	const phone = optionsData[ 0 ][ 'options' ][ 'phone' ]

	const social = ( ) => {
		if ( socialMedia.facebook || socialMedia.instagram ) {
			return (
				<SocialWidget title="Follow us" facebook={socialMedia.facebook} instagram={socialMedia.instagram} />
			)
		}
	}
	console.log(socialMedia)
	return (
		<div className={`footer-top ${className}`}>
			<div className={`column-right`}>
				{
					footerMenu && <FooterMenu menu={footerMenu.node} current={current} />
				}
			</div>
			<div className={`column-left`}>
				{
					phone && <FooterWidget title="Phone" text="+614 0241 8273" />
				}
				{
					email && <FooterWidget title="Enquires" url={`mailto:${email}`} text={email} />
				}
				{social()}
			</div>
		</div>
	)
}

export default styled( FooterTop )`
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto;
	row-gap: 0.875rem;
	@media screen and (min-width: 1280px) {
		grid-template-columns: 1fr 2fr;
		column-gap: 1rem;
	}
	
	.column-left {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: auto;
		column-gap: 1rem;
		row-gap: 1rem;
		
		@media screen and (min-width: 767px) and (max-width: 1279px) {
			grid-template-columns: 1fr 2fr 1fr;
			row-gap: 0;
		}	
		@media screen and (min-width: 1280px) {
			grid-template-columns: 1fr 1fr 1fr;
		}	
		@media screen and (max-width: 640px) {
			row-gap: 2rem;
		}
	}
	
`
