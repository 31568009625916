import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const SocialWidget = ( { title, facebook, instagram, className } ) => {

	const social = ( ) => {
		if ( facebook.url || instagram.url ) {
			return (
				<div className="social">
					{facebook.url && <a href={facebook.url}>
						<svg id="icon-facebook" viewBox="0 0 32 32">
							<path d="M19 6h5v-6h-5c-3.86 0-7 3.14-7 7v3h-4v6h4v16h6v-16h5l1-6h-6v-3c0-0.542 0.458-1 1-1z"></path>
						</svg>

					</a>}
					{instagram.url && <a href={instagram.url}>
						<svg id="icon-instagram" viewBox="0 0 32 32">
							<path d="M9.232.046h13.536a9.145 9.145 0 0 1 6.542 2.707 9.145 9.145 0 0 1 2.707 6.542V22.83a9.145 9.145 0 0 1-2.707 6.542 9.145 9.145 0 0 1-6.542 2.707H9.232a9.145 9.145 0 0 1-6.542-2.707A9.145 9.145 0 0 1-.017 22.83V9.295A9.145 9.145 0 0 1 2.69 2.753 9.145 9.145 0 0 1 9.232.046zm-.3 3.158a5.817 5.817 0 0 0-4.1 1.692 5.817 5.817 0 0 0-1.692 4.1v14.135a5.817 5.817 0 0 0 1.692 4.1 5.817 5.817 0 0 0 4.1 1.692h14.136a5.817 5.817 0 0 0 4.1-1.692 5.817 5.817 0 0 0 1.692-4.1V8.994a5.817 5.817 0 0 0-1.692-4.1 5.817 5.817 0 0 0-4.1-1.692zm15.49 2.406a2.184 2.184 0 0 1 1.391.639 1.89 1.89 0 0 1 .564 1.391 1.89 1.89 0 0 1-.564 1.391 1.89 1.89 0 0 1-1.391.564 1.89 1.89 0 0 1-1.391-.564 1.89 1.89 0 0 1-.564-1.391 1.89 1.89 0 0 1 .564-1.391 2.184 2.184 0 0 1 1.391-.639zM16 8.017a7.977 7.977 0 0 1 5.677 2.369 7.977 7.977 0 0 1 2.373 5.677 7.977 7.977 0 0 1-2.373 5.677A7.977 7.977 0 0 1 16 24.108a7.977 7.977 0 0 1-5.677-2.368 7.977 7.977 0 0 1-2.373-5.677 7.977 7.977 0 0 1 2.369-5.677A7.977 7.977 0 0 1 16 8.017zm0 3.233a4.86 4.86 0 0 0-3.384 1.429 4.591 4.591 0 0 0-1.354 3.384 4.591 4.591 0 0 0 1.354 3.384A4.591 4.591 0 0 0 16 20.8a4.591 4.591 0 0 0 3.384-1.354 4.591 4.591 0 0 0 1.354-3.384 4.591 4.591 0 0 0-1.354-3.384A4.86 4.86 0 0 0 16 11.25z"/>
						</svg>
					</a>}
				</div>
			)
		}
	}
	return (
		<div className={`social-widget ${className}`}>
			{title && <h4 className="h4 title">{title}</h4>}
			{social()}
		</div>

	)
}

SocialWidget.propTypes = {
	title: PropTypes.string,
	facebook: PropTypes.object,
	instagram: PropTypes.object,
}


export default styled( SocialWidget )`
	.title {
		margin-top: 0;
		margin-bottom: 1rem;
		color: var(--primary-color);
		font-size: 1rem;
		font-weight: 700;
	}
	.social {
		display: flex;
		align-items: center;
		
		a {
			&:not(:last-of-type) {
				margin-right: 0.75rem;
			}
			
			&:hover {
				svg {
					fill: var(--secondary-color);
				}
			}
		}
	}
	
	svg {
		display: inline-block;
		fill: var(--primary-color);
		transition: all .3s ease-in-out;
		height: 1.25rem;
		width: 1.25rem;
	}
`
