import { useStaticQuery, graphql } from 'gatsby'

export const useSiteMenuData = () => {
	const {  allWordpressMenusMenusItems  } = useStaticQuery(
		graphql`
            query MenuData {
                allWordpressMenusMenusItems {
                    edges {
                        node {
                            name
                            wordpress_id
                            wordpress_parent
                            items {
                                menu_order
                                title
                                attr_title
                                classes
                                menu_item_parent
                                object
                                object_id
                                wordpress_id
                                url
                                target
                                type_label
                                type
                                slug
                            }
                        }
                    }
                }
            }
		`
	)

	return  allWordpressMenusMenusItems.edges
}