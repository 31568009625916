import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
/**
 * Internal components
 */
import Logo from '../../images/logo.svg'

const SiteLogo = ( { siteTitle, className } ) => {

	return (
		<a href="/" title={siteTitle} className={className}>
			<figure className={`site-logo`}>
				<Logo />
			</figure>
		</a>
	)
}

SiteLogo.propTypes = {
	siteTitle: PropTypes.string
}
export default styled(SiteLogo)`

	
	svg {
		display: inline-block;
		height: 7rem;
		width: 7rem;
		@media screen and (min-width: 641px) {
			height: 10.5rem;
			width: 10.5rem;
		}
		
		.menu-open & {
			fill: var(--white) !important;
		}
	}
`