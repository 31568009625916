import { useStaticQuery, graphql } from 'gatsby'

export const useSiteOptionsData = () => {
	const {  allWordpressAcfOptions  } = useStaticQuery(
		graphql`
            query ACFOptions {
                allWordpressAcfOptions {
                    nodes {
                        options {
                            email
                            phone
                            contact_page
                        }
                    }
                }
            }
		`
	)

	return  allWordpressAcfOptions.nodes
}