import PropTypes from 'prop-types'
import React from 'react'
import {document } from 'browser-monads'
import styled from 'styled-components'
/**
 * Internal components
 */

const Hamburger = ({onClick, open, className}) => {

	const body = document.body


	if ( open ) {
		body.classList.add( 'menu-open' )
	} else {
		body.classList.remove( 'menu-open' )

	}

	return (
		<button onClick={onClick} className={`hamburger ${className}`}>
			<span className="buns">
				<span className="line"/>
				<span className="line"/>
			</span>
			<span className="title">Menu</span>
		</button>
	)
}

Hamburger.propTypes = {
	onClick: PropTypes.func
}
export default styled(Hamburger)`

	display: flex;
	align-items: center;
    background: none;
    border: 0;
    padding: 0;
    margin: 0;
  
    cursor: pointer;
    @media screen and (min-width: 641px) {
         width: 8.4rem;
    }
  .menu-open & {
    z-index: var(--z-index-high);
  }
  .buns {  
    margin-right: 1rem;
    display: block;
    position: relative;
    height: 1rem;
    width: 2.5rem;
    transition: all 0.4s;
    .menu-open & {
        margin-right: 0;
    }
    @media screen and (max-width: 640px) {
        order: 2;
         margin-left: 0.5rem;
         margin-right: 0;
         width: 2rem;
        .menu-open & {
            margin-left: 0;
        }
    }
  }

	.title {
	    color: var(--primary-color);
	    font-family: var(--headings-font-family);
	    font-weight: var(--headings-font-weight);
	    font-style: var(--headings-font-style);
	    text-transform: uppercase;
	    letter-spacing: 0.1em;
	    @media screen and (max-width: 640px) {
            order: 1;
            font-size: 0.875rem;
        }
        .menu-open & {
	       color: var(--white);
		}
	}

  &:focus {
    outline: none;
  }

  .line {
    position: absolute;
    background: var(--primary-color);
    height: 3px;
    width: 100%;
    display: block;
    transition: all 0.4s;
	border-radius: 2px;
	.menu-open & {
	   background: var(--white);
	}
		@media screen and (max-width: 640px) {
		    right: 0;
		}
    &:nth-child(1) {
      top: calc(100% - 10px);
       
      transform: translateY(-3px) rotate(0deg);
    }

    &:nth-child(2) {
      top: 10px;
      width: 60%;
      transform: translateY(0) rotate(0deg);
    }

  }

  .menu-open & {
    .line {
      &:nth-child(1) {
        top: 50%;
        width: 60%;
        transform: translateY(-50%) rotate(45deg);
        opacity: 1;
      }

      &:nth-child(2) {
        top: 50%;
          
        transform: translateY(-50%) rotate(-45deg);
        opacity: 1;
      }
    }
  }

`
