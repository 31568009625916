import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
/**
 * Internal components
 */

const Container = ( { children, className } ) => (
	<div className={`container ${className}`}>{children}</div>
)

Container.propTypes = {
	children: PropTypes.node
}

export default styled(Container) `
	margin-left: auto;
	margin-right: auto;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	width: 100%;
`