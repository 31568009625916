import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const FooterWidget = ( { title, url, text, className } ) => {

	const link = () => {
		if ( url ) {
			return (
				<p className="p"><a href={url }>{text}</a></p>
			)
		} else {
			return (
				<p className="p">{text}</p>
			)
		}
	}

	return (
		<div className={`footer-widget ${className}`}>
			{title && <h4 className="h4 title">{title}</h4>}
			{text && link()}
		</div>

	)
}

FooterWidget.propTypes = {
	title: PropTypes.string,
	url: PropTypes.string,
	text: PropTypes.string,
}


export default styled( FooterWidget )`
	.title {
		margin-top: 0;
		margin-bottom: 1rem;
		color: var(--primary-color);
		font-size: 1rem;
		font-weight: 700;
	}
	.p {
		margin-top: 0;
	}
`
